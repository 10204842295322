import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { usePathname } from 'next/navigation';
import type { RoutePath } from '@hooks/useAppRouter';
import useAccessToken from '@hooks/useAccessToken';
import useUserInfoStore from '@hooks/useUserInfoStore';
import {
  agreementValidation,
  nicknameValidation,
  phoneNumberValidation,
} from '@utils/loginValidation';
import useDeviceStore from '@hooks/useDeviceStore';
import { postAppMessage } from '@utils/appMessage';

const NEEDS_SIGN_IN = ['/home', '/profile', '/withdraw', '/sign-up'] as const;

const ONLY_APP = ['/push-history', '/home', '/story'] as const;

const NOT_CHECK_PAGE = ['/sign-up', '/setting/certificate', '/start'] as const;

const useLoginAuth = (): void => {
  const router = useRouter();
  const [accessToken] = useAccessToken();
  const [userData] = useUserInfoStore();
  const [deviceInfo] = useDeviceStore();
  const path = usePathname();

  const handleRouteChange = (url: RoutePath) => {
    postAppMessage({ cmd: 'notifyUrlChanged', path: url });
    const isNeedSignIn = NEEDS_SIGN_IN.some((page) => url.startsWith(page));
    const isPass = NOT_CHECK_PAGE.some((page) => url.startsWith(page));

    if (!isPass && userData?.userId) {
      if (agreementValidation(userData)) {
        router.push('/sign-up/step1');
      } else if (nicknameValidation(userData)) {
        router.push('/sign-up/step2');
      } else if (phoneNumberValidation(userData)) {
        router.push('/setting/certificate?redirectUri=/start');
      }
      return;
    }

    if (!isPass && isNeedSignIn) {
      if (!accessToken) {
        router.push('/login');
      }
    }
  };

  useEffect(() => {
    if (userData?.userId) {
      if (agreementValidation(userData)) {
        router.push('/sign-up/step1');
      } else if (nicknameValidation(userData)) {
        router.push('/sign-up/step2');
      } else if (phoneNumberValidation(userData)) {
        router.push('/setting/certificate?redirectUri=/start');
      }
    }
  }, [userData?.userId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!deviceInfo?.deviceId) {
      const isBack = ONLY_APP.some((page) => path.startsWith(page));
      if (isBack) router.back();
    }
  }, [path, deviceInfo?.deviceId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, accessToken, userData?.userId, path]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useLoginAuth;
