/* eslint-disable @typescript-eslint/no-use-before-define */
import { useSyncExternalStore } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useUserInfoStore = () => {
  const setUserData = (newValue: UserInfoType | null) => {
    if (typeof window !== 'undefined') {
      if (!newValue) {
        deleteUserInfo();
        return;
      }

      const currentData = localStorage.getItem('userInfo');
      const newData = JSON.stringify(newValue);

      if (currentData !== newData) {
        setUserInfoStorage(newValue);
      }
    }
  };

  const store = useSyncExternalStore(subscribe, getSnapshot, getSnapshot);

  const state =
    typeof store === 'string' ? (JSON.parse(store) as UserInfoType) : null;

  return [state, setUserData] as const;
};

const deleteUserInfo = () => {
  window.localStorage.setItem('userInfo', JSON.stringify({}));
  window.dispatchEvent(new CustomEvent('userInfoChange', { detail: {} }));
};

const subscribe = (listener: () => void) => {
  window.addEventListener('userInfoChange', listener);
  return () => {
    window.removeEventListener('userInfoChange', listener);
  };
};

const getSnapshot = () => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('userInfo');
  }
  return null;
};

export const setUserInfoStorage = (originValue: UserInfoType | {}): void => {
  window.localStorage.setItem('userInfo', JSON.stringify(originValue));
  window.dispatchEvent(
    new CustomEvent('userInfoChange', { detail: originValue }),
  );
};

export default useUserInfoStore;
