import axiosInstance from '@src/axios';
import { fourCutRankingController } from '@src/apis/urls';

const postUserHistoryTracking = async (data: {
  userId?: string;
  userLocalId?: string;
  userSessionId?: string;
}): Promise<AInstance.DefaultRes> =>
  axiosInstance.post(fourCutRankingController.USER_ENTER_HISTORY_URL, data, {
    headers: {
      Authorization: undefined,
    },
  });

export default {
  postUserHistoryTracking,
};
