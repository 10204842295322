// eslint-disable-next-line import/no-cycle
import axiosInstance from '@src/axios';
import { PushController } from '@src/apis/urls';

const postPushReservationClick = async (data: {
  pushReservationId: string;
}): Promise<AInstance.DefaultRes> =>
  axiosInstance.post(`${PushController.CLICK_URL}`, data);

export default {
  postPushReservationClick,
};
