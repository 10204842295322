// eslint-disable-next-line import/no-cycle
import axiosInstance from '@src/axios';
import { StatisticsController } from '@src/apis/urls';

const postPageViewEvent = async (data: {
  pageId: string;
  eventDateTime: string;
}): Promise<AInstance.DefaultRes | void> => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    return axiosInstance.post(`${StatisticsController.STATISTICS_URL}`, {
      pageViewEvents: [data],
    });
  }
};
export default {
  postPageViewEvent,
};
