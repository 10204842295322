// Auth Controller
const AUTH_BASE_URL = '/api/oauth2';
export const OauthController = {
  KAKAO_CODE_URL: `${AUTH_BASE_URL}/token/kakao`,
  APPLE_LOGIN_FORM_URL: `${AUTH_BASE_URL}/authorization/apple/login-form`,
  APPLE_CODE_URL: `${AUTH_BASE_URL}/token/apple`,
};

// User Controller
const USER_BASE_URL = 'api/users';
export const UserController = {
  USER_INFO_URL: `${USER_BASE_URL}/me`,
  USER_OAUTH_INFO_URL: `${USER_BASE_URL}/me/linked-profile`,
  USER_LOGOUT_URL: `${USER_BASE_URL}/logout`,
  USER_WITH_DRAW_URL: `${USER_BASE_URL}/sign-out`,
  USER_PHOTO_CARDS_URL: `${USER_BASE_URL}/me/photo-cards`,
  USER_WIN_COUNT_URL: `${USER_BASE_URL}/me/total-final-win-count`,

  USER_PROFILE: `${USER_BASE_URL}/connect/profile`,

  USER_STAMP_URL: `${USER_BASE_URL}/me/stamp`,
  USER_UNIQUE_CODE_URL: `${USER_BASE_URL}/me/unique-code`,
  USER_COUPON: `${USER_BASE_URL}/me/issued-coupon`,

  USER_STAMP_HISTORY: `${USER_BASE_URL}/me/stamp-history`,
  USER_COUPON_HISTORY: `${USER_BASE_URL}/me/issued-coupon-history`,

  USER_DEVICE_APP_URL: `${USER_BASE_URL}/app/devices`,

  USER_PUSH_NOT_VIEW_URL: `${USER_BASE_URL}/me/notification-history/not-viewed`,
  USER_PUSH_HISTORY_URL: `${USER_BASE_URL}/me/notification-history`,

  USER_PUSH_ACCESS_URL: `${USER_BASE_URL}/access`,
};

// 4cut-ranking Controller
const FOUR_CUT_RANKING_BASE_URL = 'api/ranking';
export const fourCutRankingController = {
  START_GAME_URL: `${FOUR_CUT_RANKING_BASE_URL}/games`,
  FINISH_GAME_URL: `${FOUR_CUT_RANKING_BASE_URL}/games/result`,
  GAME_INFO_URL: `${FOUR_CUT_RANKING_BASE_URL}/games`,

  ORIGIN_PHOTO_URL: `${FOUR_CUT_RANKING_BASE_URL}/photo-cards/photos/origin`,
  THUMBNAIL_PHOTO_URL: `${FOUR_CUT_RANKING_BASE_URL}/photo-cards/photos/thumbnail`,
  PHOTO_CARDS_URL: `${FOUR_CUT_RANKING_BASE_URL}/photo-cards`,
  PHOTO_CARDS_RANKING_URL: `${FOUR_CUT_RANKING_BASE_URL}/photo-cards/ranking`,
  PHOTO_CARDS_VALIDATE_URL: `${FOUR_CUT_RANKING_BASE_URL}/photo-cards/validate`,
  PHOTO_CARDS_REPORT: `${FOUR_CUT_RANKING_BASE_URL}/photo-cards/report`,

  SEASON: `${FOUR_CUT_RANKING_BASE_URL}/seasons/running`,

  USER_ENTER_HISTORY_URL: `${FOUR_CUT_RANKING_BASE_URL}/users/user-enter-histories`,
};

// Push Controller
const PUSH_BASE_URL = '/api/push';
export const PushController = {
  CLICK_URL: `${PUSH_BASE_URL}/reservation/click`,
};

// Story Controller
const STORY_BASE_URL = '/api/story';
export const StoryController = {
  STORY_URL: `${STORY_BASE_URL}`,
};

// Space Game Controller
const SPACE_GAME_BASE_URL = '/api/space-game';
export const SpaceGameController = {
  SPACE_GAME_URL: `${SPACE_GAME_BASE_URL}`,
};

const STATISTICS_BASE_URL = '/api/stat';

export const StatisticsController = {
  STATISTICS_URL: `${STATISTICS_BASE_URL}/page-view`,
};
