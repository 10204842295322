import { postAppMessage } from '@utils/appMessage';

const clientMovePath = (path: string): void => {
  const currentUrl = window.location.pathname;

  if (path === '/') {
    postAppMessage({ cmd: 'notifyUrlChanged', path });
    window.location.href = path;
    return;
  }

  if (!!path && currentUrl !== path) {
    postAppMessage({ cmd: 'notifyUrlChanged', path });
    window.location.href = path;
  }
};

export default { clientMovePath };
