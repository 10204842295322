import React from 'react';
import { useAtomValue } from 'jotai';
import QRModal from '@components/modal/QRModal';
import { qrAtom } from '@src/stores/qr';
import { barcodeAtom } from '@src/stores/barcode';
import BarcodeModal from '@components/modal/BarcodeModal';

const GlobalModals = (): JSX.Element => {
  const isQRModal = useAtomValue(qrAtom);
  const isBarcodeModal = useAtomValue(barcodeAtom);

  return (
    <>
      {isQRModal && <QRModal />}
      {!!isBarcodeModal && <BarcodeModal />}
    </>
  );
};

export default GlobalModals;
