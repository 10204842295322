import { ReactNode } from 'react';
import styled from 'styled-components';

const StampWrap = styled.div`
  border-radius: 12px;
  width: 100%;
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.09));
`;

const StampTop = styled.div`
  position: relative;
  padding: 16px 16px 12px;
  min-height: 98px;
  background-color: white;
  border-radius: 12px 12px 0 0;
`;
const StampBottom = styled.div`
  padding: 12px 16px;
  min-height: 124px;
  background-color: white;
  border-radius: 0 0 12px 12px;
`;

const Hr = styled.hr`
  margin: 0;
  border: 1px dashed ${({ theme }) => theme.colors.gray20};
  background-color: white;
  position: relative;
  overflow: visible;

  &::before,
  &::after {
    content: '';
    width: 8px;
    height: 16px;
    background-color: ${({ theme }) => theme.colors.black};
    position: absolute;
    top: -50%;
    transform: translateY(-50%);
  }

  &::before {
    right: -1px;
    border-top-left-radius: 999px;
    border-bottom-left-radius: 999px;
  }

  &::after {
    left: -1px;
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px;
  }
`;

interface Props {
  topElement: ReactNode;
  bottomElement: ReactNode;
  onClick?: () => void;
}

const TicketView = ({
  topElement,
  bottomElement,
  onClick,
}: Props): JSX.Element => {
  return (
    <StampWrap onClick={onClick}>
      <StampTop>{topElement}</StampTop>
      <Hr />
      <StampBottom>{bottomElement}</StampBottom>
    </StampWrap>
  );
};

export default TicketView;
