import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { SyncStorage } from 'jotai/utils/atomWithStorage';

interface PathType {
  prevPath: string | undefined;
  currentPath: string | undefined;
}

const storage: SyncStorage<PathType> = createJSONStorage(() => sessionStorage);

export const pathAtom = atomWithStorage<PathType>(
  'path',
  {
    prevPath: undefined,
    currentPath: undefined,
  },
  storage,
);
