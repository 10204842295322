declare global {
  /**
   * Now declare things that go in the global namespace,
   * or augment existing declarations in the global namespace.
   */
  interface Window {
    devHandlers: {
      init: (version: string) => void;
    };
  }
}

export const devHandlers = (): void => {
  if (
    typeof window !== 'undefined' &&
    process.env.NEXT_PUBLIC_APP_ENV === 'development'
  ) {
    window.devHandlers = {
      init: (version: string) => {
        window.handlers.initialize({
          appVersion: version || '1.0.0',
          deviceId: 'c38fb44d7dc1e8bd',
          devicePlatform: 'AOS',
          deviceToken:
            'fRpGvCG3TwWr7cwtiR29Wi: APA91bGVhaPfP_NVzLbAtCDsnYci8rbwHf374XxX83BuqB-Y_4II-7t5oNyHAEPNNiUwwLm5X7YwbGwhKVDWB18Ulr1b4_Do4rhctBSPzO_KXQcSW2Q_gOUvxiuVWZSlXJbwN5vB82PT',
          accessToken: '',
          refreshToken: '',
          pushReservationId: null,
          pushContentType: 'NONE',
          pushContentId: null,
          paddingTop: '',
        });
      },
    };
  }
};
