// eslint-disable-next-line import/no-cycle
import usersApi from '@src/apis/usersApi';
// eslint-disable-next-line import/no-cycle
import pushApi from '@src/apis/pushApi';
import { setUserInfoStorage } from '@hooks/useUserInfoStore';
import { MobileDataState, setDeviceStorage } from '@hooks/useDeviceStore';
import { setAccessTokenStorage } from '@hooks/useAccessToken';
import { setRefreshTokenStorage } from '@hooks/useRefreshToken';
import { OnResumeHandler } from '@utils/space-game/resume-handler';
import move from '@utils/move';
import { postAppMessage } from '@utils/appMessage';

interface MobileData extends MobileDataState {
  accessToken?: string;
  refreshToken?: string;
}

interface SocialCode {
  provider: string;
  code: string;
}

declare global {
  /**
   * Now declare things that go in the global namespace,
   * or augment existing declarations in the global namespace.
   */
  interface Window {
    Kakao: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    VConsole: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    JavaScriptChannel:
      | undefined
      | {
          postMessage: (message: string) => void;
        };
    handlers: {
      initialize: (data: MobileData) => void;
      remove: () => void;
      userAccessLog: (userId?: string) => void;
      socialComplete: (data: SocialCode) => void;
      getFriendRanking?: (phones: { phoneNumbers: string[] }) => void;
      sendState?: (state: string) => void; // 스페이스게임
      refresh?: () => void; // 스페이스게임
      aosGoBack?: () => void; // 안드로이드 뒤로가기 웹에서 위임받아 처리
      movePath: (path: string) => void;
    };
    refreshCallbackQueue: (() => void)[]; // 스페이스게임
  }
}

export const makeHandlers = (): void => {
  if (typeof window !== 'undefined') {
    window.handlers = {
      sendState: () => {},
      movePath: (path: string) => {
        const currentUrl = window.location.pathname;
        const moveUrl = `/${path}`;

        if (!!path && !currentUrl.startsWith(moveUrl)) {
          postAppMessage({ cmd: 'notifyUrlChanged', path: moveUrl });
          window.location.href = moveUrl;
        }
      },
      initialize: async (data: MobileData) => {
        setDeviceStorage(data);

        if (!data.accessToken || !data.refreshToken) {
          window.location.href = '/login';
          return;
        }

        setAccessTokenStorage(data.accessToken);
        setRefreshTokenStorage(data.refreshToken);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fetchList: Promise<any>[] = [
          usersApi.getUserInfo(),
          usersApi.postDeviceInfo({
            deviceId: data.deviceId,
            appVersion: data.appVersion,
            devicePlatform: data.devicePlatform,
            deviceToken: data.deviceToken,
          }),
        ];

        if (data.pushReservationId) {
          fetchList.push(
            pushApi.postPushReservationClick({
              pushReservationId: data.pushReservationId,
            }),
          );
        }

        const [res] = await Promise.all(fetchList);

        if (res.data) {
          setUserInfoStorage(res.data);
        }

        if (data.pushContentType !== 'NONE') {
          setDeviceStorage({
            ...data,
            pushReservationId: null,
            pushContentType: 'NONE',
            pushContentId: null,
          });
          switch (data.pushContentType) {
            case 'STORY':
              move.clientMovePath(`/story/${data.pushContentId}`);
              break;
            case 'SPACE_GAME':
              move.clientMovePath('/space');
              break;
            case 'FOURCUT_RANKING':
              move.clientMovePath('/');
              break;
            default:
              break;
          }
        }

        window.handlers.userAccessLog(res.data.userId);
      },
      remove: () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('deviceData');
        window.dispatchEvent(
          new CustomEvent('deviceDataChange', { detail: '' }),
        );
      },
      userAccessLog: async (id?: string) => {
        const storageUser = localStorage.getItem('userInfo');

        if (!storageUser) return;
        const userId = id || (JSON.parse(storageUser) as UserInfoType).userId;
        if (!userId) return;
        await usersApi.postUsersAccess({ userId });
      },
      socialComplete: (data: SocialCode) => {
        window.location.replace(
          `${window.location.origin}/social/complete?code=${data.code}&state=${data.provider}`,
        );
      },
      refresh() {
        OnResumeHandler.getInstance().runCallbacks();
      },
    };
  }
};
