/* eslint-disable @typescript-eslint/no-use-before-define */
import { useSyncExternalStore } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useRefreshToken = () => {
  const setRefreshToken = (newValue: string) => {
    if (typeof window !== 'undefined') {
      const currentData = localStorage.getItem('refreshToken');
      const newData = JSON.stringify(newValue);

      if (currentData !== newData) {
        setRefreshTokenStorage(newValue);
      }
    }
  };

  const store = useSyncExternalStore(subscribe, getSnapshot, getSnapshot);

  const state = store || '';

  return [state, setRefreshToken] as const;
};

const subscribe = (listener: () => void) => {
  window.addEventListener('refreshTokenDataChange', listener);
  return () => {
    window.removeEventListener('refreshTokenDataChange', listener);
  };
};

const getSnapshot = () => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('refreshToken');
  }
  return null;
};

export const setRefreshTokenStorage = (originValue: string): void => {
  if (originValue) {
    window.localStorage.setItem('refreshToken', JSON.stringify(originValue));
  } else {
    window.localStorage.setItem('refreshToken', '');
  }
  window.dispatchEvent(
    new CustomEvent('refreshTokenDataChange', { detail: originValue }),
  );
};

export default useRefreshToken;
