const colors = {
  white: '#ffffff',
  black: '#1a1919',
  darkNavy: '#181827',
  red: '#fa4850',
  lightRed: '#ffeded',
  gray10: '#f9f9fd',
  gray20: '#dadae4',
  gray30: '#c8c8d2',
  gray40: '#b5b5bd',
  gray50: '#9897a1',
  gray60: '#84838d',
  gray70: '#42424a',
  gray80: '#2c2c34',
  gray90: '#202027',
  gray100: '#121214',
  photoCard: '#0a0a0a',
  pink10: '#faa4ff',
  pink20: '#f759ff',
  pinkGradient: 'linear-gradient(90deg, #faa4ff 0%, #ff51f6 100%)',
  neon: '#64e1d2',
  dimmed: 'rgba(18, 18, 20, 0.6)',
  nemoPurple: '#8D4CF6',
};

const fw = {
  bold: '700',
  semiBold: '600',
  medium: '500',
  regular: '400',
};

const font = {
  title1: {
    fontSize: '24px',
    lineHeight: '34px',
    fontWeight: fw.regular,
  },
  title2: {
    fontSize: '22px',
    lineHeight: '32px',
    fontWeight: fw.regular,
  },
  title3: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: fw.regular,
  },
  title4: {
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: fw.regular,
  },
  body1: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: fw.regular,
  },
  body1_md: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: fw.medium,
  },
  body2: {
    fontSize: '15px',
    lineHeight: '18px',
    fontWeight: fw.regular,
  },
  body2_md: {
    fontSize: '15px',
    lineHeight: '18px',
    fontWeight: fw.medium,
  },
  body3: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: fw.regular,
  },
  body3_md: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: fw.medium,
  },
  body4: {
    fontSize: '13px',
    lineHeight: '20px',
    fontWeight: fw.regular,
  },
  body4_md: {
    fontSize: '13px',
    lineHeight: '20px',
    fontWeight: fw.medium,
  },
  body5_md: {
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: fw.medium,
  },
  body5: {
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: fw.regular,
  },
  body6: {
    fontSize: '10px',
    lineHeight: '18px',
    fontWeight: fw.regular,
  },
  dungGeunMo: {
    fontFamily: 'neodgm',
    letterSpacing: '0',
  },
  hGGGothicssi: {
    fontFamily: 'HGGGothicssi',
    letterSpacing: '0',
  },
  hGGGothicssi_bold: {
    fontFamily: 'HGGGothicssi',
    fontWeight: '600',
    letterSpacing: '0',
  },
  pretendard: {
    fontFamily: 'Pretendard',
    letterSpacing: '-0.6px',
  },
};

const layout = {
  width: {
    width: '100%',
    maxWidth: '480px',
    minWidth: '280px',
  },
};

const zIndex = {
  photoCard: {
    wrap: 1,
    background: -1,
    dimmed: 9,
  },
  tooltip: 9,
  ctaButton: 9,
  bottomNav: 9,
  header: 10,
  alert: 10,
  dropdown: 10,
  modal: 20,
  gameGuide: 99,
  photoCropModal: 99,
};

const transition = {
  default: 'all 0.5s ease-in-out',
  fast: 'all 0.1s ease-in-out',
};

const starBackground = {
  backgroundImage: 'url("/images/star-background.webp")',
  backgroundSize: 'cover',
  backgroundPosition: 'center 75%',
};

const gradientBackground = {
  backgroundImage: 'url("/images/gradient-background.webp")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
};

const ctaButtonContainerHeight = '80px';
const headerHeight = '52px';
const bottomNavHeight = '54px';
const mobileBottomNavHeight = '60px';

const headerSize = {
  ...layout.width,
  height: headerHeight,
  minHeight: headerHeight,
  ...font.body1,
  ...font.pretendard,
  fontWeight: fw.semiBold,
  margin: '0 auto',
  padding: '0 20px',
  zIndex: zIndex.header,
  position: 'fixed',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
};

const headerColor = {
  white: {
    background: colors.white,
    color: colors.gray100,
  },
  black: {
    background: colors.gray100,
    color: colors.white,
  },
  transparent: {
    background: 'none',
    color: colors.white,
  },
};

const bottomNav = {
  background: colors.gray90,
  borderRadius: '16px 16px 0 0',
};

const buttonSize = {
  default: {
    ...font.body1_md,
    height: '50px',
    borderRadius: '12px',
    borderWidth: '1px',
  },
};

const buttonColor = {
  white: {
    default: {
      background: colors.white,
      color: colors.gray100,
    },
    pressed: {
      background: colors.gray20,
      color: colors.gray100,
    },
    disabled: {
      background: colors.gray80,
      border: '1px solid transparent',
      color: colors.gray60,
      pointerEvents: 'none',
    },
  },
  outlined: {
    default: {
      background: 'none',
      borderColor: colors.gray70,
      borderStyle: 'solid',
      color: colors.gray40,
    },
    pressed: {
      background: 'none',
      borderColor: colors.gray70,
      borderStyle: 'solid',
      color: colors.gray50,
    },
    disabled: {
      background: 'none',
      borderColor: colors.gray40,
      borderStyle: 'solid',
      color: colors.gray40,
      pointerEvents: 'none',
    },
  },
  black: {
    default: {
      background: colors.gray100,
      color: colors.white,
    },
    pressed: {
      background: colors.gray70,
      color: colors.white,
    },
    disabled: {
      background: colors.gray10,
      border: '1px solid transparent',
      color: colors.gray30,
      pointerEvents: 'none',
    },
  },
  gray80: {
    default: {
      background: colors.gray80,
      color: colors.white,
    },
    pressed: {
      background: colors.gray70,
      color: colors.white,
    },
    disabled: {
      background: colors.gray10,
      border: '1px solid transparent',
      color: colors.gray30,
      pointerEvents: 'none',
    },
  },
};

const textInputSize = {
  medium: {
    ...font.body1,
    padding: '13px 11px',
    borderRadius: '12px',
    borderWidth: '1px',
    outline: 'none',
  },
  small: {
    ...font.body3,
    padding: '13px 11px',
    borderRadius: '12px',
    borderWidth: '1px',
    outline: 'none',
  },
};

const textInputColor = {
  black: {
    default: {
      'background': colors.gray80,
      'borderStyle': 'solid',
      'borderColor': colors.gray70,
      'caretColor': colors.white,
      'color': colors.white,
      '::placeholder': {
        color: colors.gray60,
      },
    },
    focused: {
      borderColor: colors.gray50,
    },
  },
  white: {
    default: {
      'background': colors.white,
      'borderStyle': 'solid',
      'borderColor': colors.gray20,
      'caretColor': colors.gray60,
      'color': colors.black,
      '::placeholder': {
        color: colors.gray30,
      },
    },
    focused: {
      borderColor: colors.gray100,
    },
  },
};

export const defaultTheme = {
  fw,
  font,
  layout,
  zIndex,
  transition,
  starBackground,
  gradientBackground,
  ctaButtonContainerHeight,
  bottomNavHeight,
  headerHeight,
  headerSize,
  headerColor,
  bottomNav,
  buttonSize,
  buttonColor,
  textInputSize,
  textInputColor,
  mobileBottomNavHeight,
  colors,
};

export type PGDefaultTheme = typeof defaultTheme;
