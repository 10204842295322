import styled from 'styled-components';
import ModalCancel from '@icons/mobile/modal_cancel.svg';

export const DimmedWrap = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.modal - 1};
  opacity: 0;

  &.up {
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }

  &.down {
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
`;

export const BackgroundWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.black};
  position: fixed;
  left: 50%;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.modal};
  transform: translate(-50%, 100%);

  &.up {
    transform: translate(-50%, 0);
    transition: transform 0.3s ease-in-out;
  }

  &.down {
    transform: translate(-50%, 100%);
    transition: transform 0.3s ease-in-out;
  }
`;

export const QRWrap = styled.div`
  padding: 48px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const QRBorderWrap = styled.div`
  position: relative;
  padding: 8px;
  margin: 16px 0 20px;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 16px;
    padding: 8px;
    background: linear-gradient(
      to bottom,
      black,
      ${({ theme }) => theme.colors.red}
    );
    -webkit-mask: linear-gradient(${({ theme }) => theme.colors.white} 0 0)
        content-box,
      linear-gradient(${({ theme }) => theme.colors.white} 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  canvas {
    margin: 16px;
  }
`;

export const UniqueCodeWrap = styled.div`
  padding: 8px 16px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.lightRed};
  ${({ theme }) => theme.font.body3};

  span {
    margin-right: 16px;
    font-weight: 600;
  }
`;

export const TitleWrap = styled.div`
  ${({ theme }) => theme.font.title3};
  color: ${({ theme }) => theme.colors.gray100};
  font-weight: ${({ theme }) => theme.fw.semiBold};
`;

export const DescriptionWrap = styled.span`
  ${({ theme }) => theme.font.body2};
  color: ${({ theme }) => theme.colors.gray60};
  text-align: center;
`;

export const CancelWrap = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  color: white;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);

  span {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray20};
    color: ${({ theme }) => theme.colors.gray20};
  }
`;

export const CancelButton = styled(ModalCancel)`
  padding: 5px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray60};
  border: 1px solid ${({ theme }) => theme.colors.gray40};
  width: 26px;
  height: 26px;
  margin-right: 8px;
`;
