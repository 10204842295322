import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import { postAppMessage } from '@utils/appMessage'; // eslint-disable-line import/no-cycle
import { setAccessTokenStorage } from '@hooks/useAccessToken';
import { setRefreshTokenStorage } from '@hooks/useRefreshToken';
import { setUserInfoStorage } from '@hooks/useUserInfoStore';

const INVALID_ACCESS_TOKEN = 'S4_0001';
const INVALID_REFRESH_TOKEN = 'S4_0002';
const EXPIRED_ACCESS_TOKEN = 'S4_0003';
const EXPIRED_REFRESH_TOKEN = 'S4_0004';
const SUSPENDED_USER = 'S4_0007';
const WITHDRAWAL_USER = 'S4_0008';
const KICKED_USER = 'S4_0009';

const handleForceLogout = () => {
  setAccessTokenStorage('');
  setRefreshTokenStorage('');
  setUserInfoStorage({});
  window.location.href = '/login';
};

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  timeout: 10000,
  withCredentials: true,
  paramsSerializer: (params) => qs.stringify(params, { indices: false }),
});

let isTokenRefreshing = false;
let requestQueue: {
  resolve: (value: Promise<AInstance.Res<any>> | any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  reject: (reason?: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
}[] = [];

axiosInstance.interceptors.request.use(
  // @ts-ignore
  (config: AxiosRequestConfig) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${JSON.parse(token)}`,
      };
    }
    return config;
  },
  (error: AxiosError) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const originalConfig = error.config;
    if (error.response) {
      // @ts-ignore
      const { code } = error.response.data;

      if (code === EXPIRED_ACCESS_TOKEN) {
        if (!isTokenRefreshing) {
          isTokenRefreshing = true;

          const refreshToken = localStorage.getItem('refreshToken');
          if (refreshToken) {
            try {
              const response = await axiosInstance.post(
                '/api/users/token/reissue',
                {
                  refreshToken: JSON.parse(refreshToken),
                },
                {
                  headers: { Authorization: undefined },
                },
              );

              const { accessToken, refreshToken: newRefreshToken } =
                response.data;
              setAccessTokenStorage(accessToken);
              setRefreshTokenStorage(newRefreshToken);
              postAppMessage({
                cmd: 'storeAuthToken',
                accessToken,
                refreshToken: newRefreshToken,
              });

              isTokenRefreshing = false;

              // @ts-ignore
              originalConfig.headers.Authorization = `Bearer ${accessToken}`;

              requestQueue.forEach((p) => {
                // @ts-ignore
                p.resolve(`Bearer ${accessToken}`);
              });

              requestQueue = [];

              // @ts-ignore
              return await Promise.resolve(axiosInstance(originalConfig));
            } catch (reissueError) {
              isTokenRefreshing = false;
              requestQueue.forEach((p) => p.reject(reissueError));
              requestQueue = [];
              // handleForceLogout();
              return Promise.reject(reissueError);
            }
          }
        }
        return new Promise((resolve, reject) => {
          requestQueue.push({
            resolve: (token: string) => {
              // @ts-ignore
              originalConfig.headers.Authorization = token;

              // @ts-ignore
              resolve(axiosInstance(originalConfig));
            },
            reject: (err: AxiosError) => reject(err),
          });
        });
      }
      if (
        [
          INVALID_ACCESS_TOKEN,
          INVALID_REFRESH_TOKEN,
          EXPIRED_REFRESH_TOKEN,
          SUSPENDED_USER,
          WITHDRAWAL_USER,
          KICKED_USER,
        ].includes(code) ||
        error.response.status === 403
      ) {
        handleForceLogout();
      } else if (
        error.response.status === 400 ||
        error.response.status === 401
      ) {
        return {
          status: error.response.status,
          statusText: error.response.statusText,
        };
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
