import { Suspense, useCallback, useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import QRCode from 'qrcode.react';
import { qrAtom } from '@src/stores/qr';
import ProgressBalls from '@components/common/ProgressBalls';
import { showSimpleToast } from '@utils/toastUtils';
import useUserInfoStore from '@hooks/useUserInfoStore';
import {
  BackgroundWrap,
  CancelButton,
  CancelWrap,
  DescriptionWrap,
  DimmedWrap,
  QRBorderWrap,
  QRWrap,
  TitleWrap,
  UniqueCodeWrap,
} from '@components/modal/styles/qrModal.style';
import { postAppMessage } from '@utils/appMessage';
import { customTagManager } from '@src/customTagManager';
import usersQuery from '@src/apis/querys/usersQuery';

const QRComponent = () => {
  const { data } = usersQuery.useGetMyUniqueCode();

  return (
    <QRWrap>
      {data && (
        <>
          <TitleWrap>적립 QR코드</TitleWrap>
          <DescriptionWrap>
            포토부스 카메라에
            <br />
            QR코드를 인식해 주세요!
          </DescriptionWrap>
          <QRBorderWrap>
            <QRCode id={data} value={data} size={200} />
          </QRBorderWrap>
          <UniqueCodeWrap>
            <span>QR번호</span>
            {data.replace(/(.{4})/g, '$1 - ').replace(/ - $/g, '')}
          </UniqueCodeWrap>
        </>
      )}
    </QRWrap>
  );
};

const QRModal = (): JSX.Element => {
  const [isModal, setIsModal] = useState(false);
  const [isAtomModal, setIsAtomModal] = useAtom(qrAtom);
  const [userData] = useUserInfoStore();

  useEffect(() => {
    if (!userData?.userId) {
      setIsAtomModal(false);
      showSimpleToast({
        type: 'error',
        content: '로그인 후 이용 가능합니다.',
      });
    }
  }, [userData?.userId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    postAppMessage({
      cmd: 'barcodeMode',
      bright: isModal,
    });
  }, [isModal]);

  useEffect(() => {
    if (isAtomModal) {
      setIsModal(true);
      customTagManager.qrPopupView();
    }
  }, [isAtomModal]);

  const closeModal = useCallback(() => {
    setIsModal(false);
    setTimeout(() => {
      setIsAtomModal(false);
    }, 300);
  }, [isModal, isAtomModal]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <DimmedWrap className={isModal ? 'up' : 'down'} />
      <BackgroundWrap className={isModal ? 'up' : 'down'}>
        <Suspense fallback={<ProgressBalls />}>
          <QRComponent />
        </Suspense>
        <CancelWrap onClick={closeModal}>
          <CancelButton />
          <span>이전 화면으로 가기</span>
        </CancelWrap>
      </BackgroundWrap>
    </>
  );
};

export default QRModal;
