import styled from 'styled-components';
import ModalCancel from '@icons/mobile/modal_cancel.svg';

export const DimmedWrap = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.modal - 1};
  opacity: 0;

  &.up {
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }

  &.down {
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
`;

export const BackgroundWrap = styled.div`
  ${({ theme }) => theme.layout.width};
  height: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.black};
  position: fixed;
  left: 50%;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.modal};
  transform: translate(-50%, 100%);

  &.up {
    transform: translate(-50%, 0);
    transition: transform 0.3s ease-in-out;
  }

  &.down {
    transform: translate(-50%, 100%);
    transition: transform 0.3s ease-in-out;
  }
`;

export const BarcodeWrap = styled.div`
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 24px 14px 0;
  left: 50%;
  transform: translateX(-50%);

  &.full {
    width: 100%;
  }

  .title {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .left {
    justify-content: space-between;
  }

  .description {
    ${({ theme }) => theme.font.title3};
    font-weight: ${({ theme }) => theme.fw.semiBold};
    text-align: center;
    margin-top: 8px;

    span {
      color: ${({ theme }) => theme.colors.red};
    }
  }

  text {
    display: none;
  }

  .barcode-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    transform: translateY(-12px);

    .code {
      text-align: center;
      ${({ theme }) => theme.font.title4}
      font-weight: 600;
      flex: 1 0 auto;
    }

    .is-center {
      text-align: left;
    }
  }
`;

export const ExpiredInfoWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: content-box;
  margin: 20px 0;

  .expired-info {
    background-color: ${({ theme }) => theme.colors.gray10};
    gap: 4px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    color: ${({ theme }) => theme.colors.gray60};

    .expired-time {
      ${({ theme }) => theme.font.body1};
      font-weight: 500;
      white-space: nowrap;
    }

    .expired-description {
      ${({ theme }) => theme.font.body5};
    }
  }
`;

export const CancelWrap = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  color: white;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);

  .cancel-text {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray20};
    color: ${({ theme }) => theme.colors.gray20};
  }
`;

export const CancelButton = styled(ModalCancel)`
  padding: 5px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray60};
  border: 1px solid ${({ theme }) => theme.colors.gray40};
  width: 26px;
  height: 26px;
  margin-right: 8px;
`;
