/* eslint-disable @typescript-eslint/explicit-module-boundary-types,  @typescript-eslint/no-use-before-define */
import { useSyncExternalStore } from 'react';

const useAccessToken = () => {
  const setAccessToken = (newValue: string) => {
    if (typeof window !== 'undefined') {
      const currentData = localStorage.getItem('accessToken');
      const newData = JSON.stringify(newValue);

      if (currentData !== newData) {
        setAccessTokenStorage(newValue);
      }
    }
  };

  const store = useSyncExternalStore(subscribe, getSnapshot, getSnapshot);

  const state = store || '';

  return [state, setAccessToken] as const;
};

const subscribe = (listener: () => void) => {
  window.addEventListener('AccessTokenDataChange', listener);
  return () => {
    window.removeEventListener('AccessTokenDataChange', listener);
  };
};

const getSnapshot = () => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('accessToken');
  }
  return null;
};

export const setAccessTokenStorage = (originValue: string): void => {
  if (originValue) {
    window.localStorage.setItem('accessToken', JSON.stringify(originValue));
  } else {
    window.localStorage.setItem('accessToken', '');
  }
  window.dispatchEvent(
    new CustomEvent('AccessTokenDataChange', { detail: originValue }),
  );
};

export default useAccessToken;
