const colorV2 = {
  white: '#FFFFFF',
  gray10: '#F9F9FD',
  gray15: '#F4F4FB',
  gray20: '#DADAE4',
  gray30: '#C8C8D2',
  gray40: '#B5B5BD',
  gray50: '#9897A1',
  gray60: '#84838D',
  gray70: '#42424A',
  gray80: '#2C2C34',
  gray90: '#202027',
  gray100: '#121214',
  primary: {
    neonPurple: '#8D4CF6',
    purple: '#8F5EE9',
    normal: '#FA4850',
  },
  line: {
    normal: '#F4F4FB',
    strong: '#DADAE4',
    heavy: '#C8C8D2',
  },
  fill: {
    normal: '#70737C14', // #70737C, 8%
    strong: '#70737C29', // #70737C, 16%
    alternative: '#70737C0D', // #70737C, 5%
  },
  dark: {
    fill: '#261A3D',
    lightPurple01: '#B08AF7',
    lightPurple02: '#A571F9',
  },
};

const fontWeightV2 = {
  bold: '700',
  semiBold: '600',
  medium: '500',
  regular: '400',
};

const fontFamilyV2 = {
  pretendard: {
    fontFamily: 'Pretendard',
    letterSpacing: '-0.6px',
  },
  hGGGothicssi: {
    fontFamily: 'HGGGothicssi',
  },
  dungGeunMo: {
    fontFamily: 'neodgm',
  },
};

const fontSizeV2 = {
  title1: {
    fontSize: '24px',
    lineHeight: '34px',
  },
  title2: {
    fontSize: '22px',
    lineHeight: '32px',
  },
  heading1: {
    fontSize: '20px',
    lineHeight: '28px',
  },
  heading2: {
    fontSize: '18px',
    lineHeight: '26px',
  },
  body1: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  body2: {
    fontSize: '15px',
    lineHeight: '22px',
  },
  body3: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  caption1: {
    fontSize: '13px',
    lineHeight: '18px',
  },
  caption2: {
    fontSize: '12px',
    lineHeight: '16px',
  },
};

const buttonSizeV2 = {
  large: {
    ...fontSizeV2.body1,
    fontWeight: fontWeightV2.semiBold,
    height: '52px',
    borderRadius: '12px',
    borderWidth: '1px',
    borderStyle: 'solid',
    gap: '8px',
    paddingLeft: '28px',
    paddingRight: '28px',
  },
  medium: {
    ...fontSizeV2.body1,
    fontWeight: fontWeightV2.medium,
    height: '45px',
    borderRadius: '8px',
    borderWidth: '1px',
    borderStyle: 'solid',
    gap: '8px',
    paddingLeft: '28px',
    paddingRight: '28px',
  },
  small: {
    ...fontSizeV2.caption1,
    fontWeight: fontWeightV2.medium,
    height: '32px',
    borderRadius: '8px',
    borderWidth: '1px',
    borderStyle: 'solid',
    gap: '6px',
    paddingLeft: '14px',
    paddingRight: '14px',
  },
};

/**
 * light/solid/primary
 * light/solid/secondary
 * light/outlined/primary
 * light/outlined/secondary
 * dark/solid/primary
 * dark/outlined/primary
 * */
const buttonColorV2 = {
  light: {
    solid: {
      primary: {
        default: {
          background: colorV2.gray80,
          borderColor: 'transparent',
          color: colorV2.white,
        },
        pressed: {
          background: colorV2.gray80,
          borderColor: 'transparent',
          color: colorV2.white,
        },
        disabled: {
          background: colorV2.gray15,
          borderColor: 'transparent',
          color: colorV2.gray20,
        },
      },
      secondary: {
        default: {
          background: colorV2.gray30,
          borderColor: 'transparent',
          color: colorV2.gray100,
        },
        pressed: {
          background: colorV2.gray30,
          borderColor: 'transparent',
          color: colorV2.gray100,
        },
        disabled: {
          background: colorV2.gray15,
          borderColor: 'transparent',
          color: colorV2.gray20,
        },
      },
    },
    outlined: {
      primary: {
        default: {
          background: colorV2.white,
          borderColor: colorV2.gray80,
          color: colorV2.gray100,
        },
        pressed: {
          background: colorV2.white,
          borderColor: colorV2.gray80,
          color: colorV2.gray100,
        },
        disabled: {
          background: colorV2.white,
          borderColor: colorV2.gray20,
          color: colorV2.gray20,
        },
      },
      secondary: {
        default: {
          background: 'transparent',
          borderColor: colorV2.gray20,
          color: colorV2.gray100,
        },
        pressed: {
          background: '#8D4CF60F',
          borderColor: colorV2.primary.neonPurple,
          color: colorV2.primary.neonPurple,
        },
        disabled: {
          background: 'transparent',
          borderColor: colorV2.gray15,
          color: colorV2.gray20,
        },
      },
    },
  },
  dark: {
    solid: {
      primary: {
        default: {
          background: colorV2.white,
          borderColor: 'transparent',
          color: colorV2.gray100,
        },
        pressed: {
          background: colorV2.white,
          borderColor: 'transparent',
          color: colorV2.gray100,
        },
        disabled: {
          background: colorV2.gray80,
          borderColor: 'transparent',
          color: colorV2.gray70,
        },
      },
    },
    outlined: {
      primary: {
        default: {
          background: colorV2.gray100,
          borderColor: colorV2.gray60,
          color: colorV2.white,
        },
        pressed: {
          background: colorV2.gray100,
          borderColor: colorV2.gray60,
          color: colorV2.white,
        },
        disabled: {
          background: colorV2.gray100,
          borderColor: colorV2.gray80,
          color: colorV2.gray70,
        },
      },
      secondary: {
        default: {
          background: colorV2.gray90,
          borderColor: colorV2.gray70,
          color: colorV2.gray40,
        },
        pressed: {
          background: colorV2.dark.fill,
          borderColor: colorV2.primary.purple,
          color: colorV2.dark.lightPurple01,
        },
        disabled: {
          background: colorV2.gray100,
          borderColor: colorV2.gray80,
          color: colorV2.gray70,
          pointerEvents: 'auto',
        },
      },
    },
  },
};

export const themeV2 = {
  colorV2,
  fontWeightV2,
  fontFamilyV2,
  fontSizeV2,
  buttonSizeV2,
  buttonColorV2,
};
