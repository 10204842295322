import styled from 'styled-components';
import More from '@icons/mobile/more.svg';

const FullSizeBarcodeWrap = styled.button`
  display: flex;
  flex: 0 0 auto;
  alignitems: center;
  gap: 4px;
`;

const Text = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray40};
`;

interface Props {
  onClick?: () => void;
}

const FullSizeBarcodeButton = ({ onClick }: Props): JSX.Element => {
  return (
    <FullSizeBarcodeWrap onClick={onClick}>
      <More />
      <Text>크게보기</Text>
    </FullSizeBarcodeWrap>
  );
};

export default FullSizeBarcodeButton;
