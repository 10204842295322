// eslint-disable-next-line import/no-cycle
import axiosInstance from '@src/axios';
import { UserController } from '@src/apis/urls';
import { MobileData } from '@hooks/useDeviceStore';
import { PushType } from '@features/push-history/types';
import { ResStampHistoryType, StampType } from '@src/apis/types/stamp';
import { ResCouponHistoryType, ResCouponType } from '@src/apis/types/coupon';

const getUserInfo = async (): Promise<AInstance.Res<UserInfoType>> =>
  axiosInstance.get(UserController.USER_INFO_URL);

const getUserInfoWithToken = async (
  token: string,
): Promise<AInstance.Res<UserInfoType>> =>
  axiosInstance.get(UserController.USER_INFO_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const getUserOAuthInfoWithToken = async (
  token: string,
): Promise<AInstance.Res<{ profileInfos: UserOAuthInfoType }>> =>
  axiosInstance.get(UserController.USER_OAUTH_INFO_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const deleteLogout = async (): Promise<AInstance.DefaultRes> =>
  axiosInstance.delete(UserController.USER_LOGOUT_URL);

const withdrawUser = async (data: {
  cause: string;
  detailCause: string;
}): Promise<AInstance.DefaultRes> =>
  axiosInstance.delete(UserController.USER_WITH_DRAW_URL, { data });

const postProfileImage = async (
  data: FormData,
): Promise<AInstance.Res<{ profileImageUrl: string }>> =>
  axiosInstance.post('/api/users/me/profile-image', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

const patchProfileInfo = async (data: {
  profileImageUrl: string;
  photograyNickname: string;
  introduction: string;
  instagramProfileUrl: string;
  tiktokProfileUrl: string;
  region?: RegionType;
}): Promise<AInstance.DefaultRes> => axiosInstance.patch('/api/users/me', data);

const patchEditPhoneNumber = async (data: {
  countryNumber: string;
  phoneNumber: string;
}): Promise<AInstance.DefaultRes> =>
  axiosInstance.patch('/api/users/me/phone', data);

const getMyPhotoCards = async (params: {
  page: number;
  size: number;
  sort: string;
}): Promise<AInstance.Res<PageResultType<PhotoCardType>>> =>
  axiosInstance.get(UserController.USER_PHOTO_CARDS_URL, { params });

const getMyPhotoCardsWithToken = async (
  token: string,
): Promise<AInstance.Res<PageResultType<PhotoCardType>>> =>
  axiosInstance.get(UserController.USER_PHOTO_CARDS_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const deleteMyPhotoCard = async (params: {
  photoCardId: string;
}): Promise<AInstance.DefaultRes> =>
  axiosInstance.delete(
    `${UserController.USER_PHOTO_CARDS_URL}/${params.photoCardId}`,
  );

const getMyWinCount = async (): Promise<
  AInstance.Res<{
    totalFinalWinCount: number;
    totalPickCount: number;
    prevTotalFinalWinCount: number;
    prevTotalPickCount: number;
  }>
> => axiosInstance.get(UserController.USER_WIN_COUNT_URL);

const getMyStamp = async (): Promise<AInstance.Res<StampType[]>> =>
  axiosInstance.get(UserController.USER_STAMP_URL);

const getMyUniqueCode = async (): Promise<
  AInstance.Res<{ userUniqueCode: string }>
> => axiosInstance.get(UserController.USER_UNIQUE_CODE_URL);

const getOtherUserInfo = async (
  userId: string,
): Promise<AInstance.Res<ProfileInfoType>> =>
  axiosInstance.get(`/api/users/${userId}/info`);

const getUserSnsInfo = async (
  userId: string,
): Promise<AInstance.Res<{ profileInfos: UserOAuthInfoType }>> =>
  axiosInstance.get(`/api/users/${userId}/linked-profile`);

const getOtherUserWinCount = async (
  userId: string,
): Promise<
  AInstance.Res<{
    totalFinalWinCount: number;
    totalPickCount: number;
  }>
> => axiosInstance.get(`/api/users/${userId}/total-final-win-count`);

const getOtherUserPhotoCards = async (params: {
  userId: string;
  page: number;
  size: number;
  sort: string;
}): Promise<AInstance.Res<PageResultType<PhotoCardType>>> =>
  axiosInstance.get(`/api/users/${params.userId}/photo-cards`, { params });

const getMyCoupon = async (): Promise<AInstance.Res<ResCouponType[]>> =>
  axiosInstance.get(UserController.USER_COUPON);

const patchAgreement = async (data: {
  ageAgreement: boolean;
  serviceAgreement: boolean;
  personalInfoCollectAgreement: boolean;
  emailMarketingAgreement: boolean;
  smsMarketingAgreement: boolean;
}): Promise<AInstance.DefaultRes> =>
  axiosInstance.patch('/api/users/agreements', data);

const postPhotograyNicknameDuplication = async (data: {
  photograyNickname: string;
}): Promise<AInstance.DefaultRes> =>
  axiosInstance.post(`/api/users/validation/photogray-nickname`, data);

const getStampHistory = async (params: {
  startDateTime: string;
  endDateTime: string;
  stampHistorySort: string;
}): Promise<AInstance.Res<ResStampHistoryType[]>> =>
  axiosInstance.get(UserController.USER_STAMP_HISTORY, { params });

const getCouponHistory = async (params: {
  startDateTime: string;
  endDateTime: string;
  issuedCouponHistorySort: string;
}): Promise<AInstance.Res<ResCouponHistoryType[]>> =>
  axiosInstance.get(UserController.USER_COUPON_HISTORY, { params });

const getPushNotView = async (): Promise<AInstance.Res<boolean>> =>
  axiosInstance.get(UserController.USER_PUSH_NOT_VIEW_URL);

const getPushHistory = async (): Promise<AInstance.Res<PushType[]>> =>
  axiosInstance.get(UserController.USER_PUSH_HISTORY_URL);

const patchPhotograyNickname = async (data: {
  photograyNickname: string;
}): Promise<AInstance.DefaultRes> =>
  axiosInstance.patch(`/api/users/me/photogray-nickname`, data);

const patchNotificationAgreement = async (data: {
  promotionNotificationAgreement: boolean;
  activityNotificationAgreement: boolean;
}): Promise<AInstance.DefaultRes> =>
  axiosInstance.patch(`/api/users/agreements/notification`, data);

const postDeviceInfo = async (
  data: MobileData,
): Promise<AInstance.DefaultRes> =>
  axiosInstance.post(UserController.USER_DEVICE_APP_URL, data);

const getNewCouponVisible = async (): Promise<
  AInstance.Res<{
    popupVisible: false;
  }>
> => axiosInstance.get('/api/users/me/issued-coupon/popup-visible');

const postUsersAccess = async (data: {
  userId: string;
}): Promise<AInstance.DefaultRes> =>
  axiosInstance.post(UserController.USER_PUSH_ACCESS_URL, data);

const patchRegion = async (data: { region: string }) =>
  axiosInstance.patch('api/users/me/region', data);

export default {
  getUserInfo,
  getUserInfoWithToken,
  getUserOAuthInfoWithToken,
  deleteLogout,
  withdrawUser,
  postProfileImage,
  patchProfileInfo,
  patchEditPhoneNumber,
  getMyPhotoCards,
  getMyPhotoCardsWithToken,
  deleteMyPhotoCard,
  getMyWinCount,
  getMyStamp,
  getMyUniqueCode,
  getOtherUserInfo,
  getUserSnsInfo,
  getOtherUserWinCount,
  getOtherUserPhotoCards,
  getMyCoupon,
  patchAgreement,
  getStampHistory,
  getCouponHistory,
  postPhotograyNicknameDuplication,
  patchPhotograyNickname,
  patchNotificationAgreement,
  postDeviceInfo,
  getPushNotView,
  getPushHistory,
  getNewCouponVisible,
  postUsersAccess,
  patchRegion,
};
