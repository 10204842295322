/* eslint-disable @typescript-eslint/no-use-before-define */
import { useSyncExternalStore } from 'react';

export interface MobileData {
  deviceToken?: string;
  appVersion?: string;
  deviceId?: string;
  devicePlatform?: string;
}

export interface MobileDataState extends MobileData {
  pushReservationId: string | null;
  pushContentType: 'NONE' | 'STORY' | 'SPACE_GAME' | 'FOURCUT_RANKING';
  pushContentId: string | null;
  paddingTop: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useDeviceStore = () => {
  const setDeviceData = (newValue: MobileDataState) => {
    if (typeof window !== 'undefined') {
      const currentData = localStorage.getItem('deviceData');
      const newData = JSON.stringify(newValue);

      if (currentData !== newData) {
        setDeviceStorage(newValue);
      }
    }
  };

  const store = useSyncExternalStore(subscribe, getSnapshot, getSnapshot);

  const state =
    typeof store === 'string' ? (JSON.parse(store) as MobileDataState) : null;

  return [state, setDeviceData] as const;
};

const subscribe = (listener: () => void) => {
  window.addEventListener('deviceDataChange', listener);
  return () => {
    window.removeEventListener('deviceDataChange', listener);
  };
};

const getSnapshot = () => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('deviceData');
  }
  return null;
};

export const setDeviceStorage = (originValue: MobileDataState): void => {
  window.localStorage.setItem('deviceData', JSON.stringify(originValue));
  window.dispatchEvent(
    new CustomEvent('deviceDataChange', { detail: originValue }),
  );
};

export default useDeviceStore;
