import type { AppProps } from 'next/app';
import Script from 'next/script';
import React, { ReactElement, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useAtomsDebugValue, useAtomsDevtools } from 'jotai/devtools';
import { QueryClientProvider } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import { DefaultSeo } from 'next-seo';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { useAtom } from 'jotai';
import { pathAtom } from '@src/stores/path';
import { GlobalStyles } from '@styles/global-styles';
import { defaultTheme } from '@styles/theme';
import '@styles/reactToastify.css';
import '@styles/reactDateRange.css';
import '@public/fonts/font.css';
import '@styles/typoStyle.css';
import { userLocalIdAtom, userSessionIdAtom } from '@src/stores/user';
import userHistoryApi from '@src/apis/4cut-ranking/userHistoryApi';
import DEFAULT_SEO from '@src/seo';
import GlobalModals from '@components/layout/GlobalModals';
import { GlobalPortal } from '@components/portal/GlobalPortal';
import useLoginAuth from '@hooks/useLoginAuth';
import { makeHandlers } from '@utils/WebAppConnection';
import useAccessToken from '@hooks/useAccessToken';
import { customTagManager } from '@src/customTagManager';
import { RoutePath } from '@hooks/useAppRouter';
import { devHandlers } from '@utils/devHandeler';
import queryClient from '@src/libs/react-query';
import { themeV2 } from '@styles/theme_v2';

makeHandlers();
devHandlers();

const DebugAtoms = () => {
  useAtomsDebugValue();
  return null;
};

const AtomsDevtools = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  useAtomsDevtools('devtools');
  return children;
};

const tagManagerArgs = {
  gtmId: 'GTM-5LCKGHG',
};

const MyApp = ({ Component, pageProps }: AppProps): ReactElement => {
  const router = useRouter();
  const [path, setPath] = useAtom(pathAtom);
  useLoginAuth();

  function kakaoInit() {
    // 페이지가 로드되면 실행
    window.Kakao.init(process.env.NEXT_PUBLIC_KAKAO_SDK);
  }

  const [accessToken = ''] = useAccessToken();
  const [userLocalId, setUserLocalId] = useAtom(userLocalIdAtom);
  const [userSessionId, setUserSessionId] = useAtom(userSessionIdAtom);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_ENV === 'production') {
      TagManager.initialize(tagManagerArgs);
    }

    if (!userLocalId) {
      setUserLocalId(uuidv4());
    }
    if (!userSessionId) {
      setUserSessionId(uuidv4());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // userLocalId, userSessionId 생성 후 동작
    if (userLocalId && userSessionId) {
      if (!accessToken) {
        // 비회원
        userHistoryApi.postUserHistoryTracking({
          userLocalId,
          userSessionId,
        });
      }
    }
  }, [userLocalId, userSessionId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // 로그인 로딩 화면('/login/complete')은 pathAtom 에 쌓이지 않도록 처리
    const loginRedirectUriPath = '/login/complete';
    if (
      path.currentPath !== router.asPath &&
      !router.asPath.includes(loginRedirectUriPath)
    ) {
      setPath((prev) => ({
        prevPath: prev.currentPath,
        currentPath: router.asPath,
      }));
    }

    customTagManager.staticPageView(router.asPath as RoutePath);
  }, [router.asPath]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <GlobalPortal.Provider>
      <QueryClientProvider client={queryClient}>
        <GlobalStyles />
        <ThemeProvider theme={{ ...defaultTheme, ...themeV2 }}>
          <DebugAtoms />
          <ToastContainer />
          <DefaultSeo {...DEFAULT_SEO} />
          <AtomsDevtools>
            <>
              <Component {...pageProps} />
              <GlobalModals />
              <Script
                src="https://t1.kakaocdn.net/kakao_js_sdk/2.7.0/kakao.min.js"
                onLoad={() => kakaoInit()}
                integrity="sha384-l+xbElFSnPZ2rOaPrU//2FF5B4LB8FiX5q4fXYTlfcG4PGpMkE1vcL7kNXI6Cci0"
                crossOrigin="anonymous"
              />
              {process.env.NEXT_PUBLIC_APP_ENV !== 'production' && (
                <Script
                  src="https://unpkg.com/vconsole@latest/dist/vconsole.min.js"
                  onLoad={() => new window.VConsole()}
                />
              )}
            </>
          </AtomsDevtools>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </GlobalPortal.Provider>
  );
};

export default appWithTranslation(MyApp);
