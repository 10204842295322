import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { SyncStorage } from 'jotai/utils/atomWithStorage';

// 비회원 GA 로컬 트래킹용 uuid
export const userLocalIdAtom = atomWithStorage<string | undefined>(
  'userLocalId',
  undefined, // localStorage
);

// 비회원 GA 세션 트래킹용 uuid
export const userSessionIdAtom = atomWithStorage<string | undefined>(
  'userSessionId',
  undefined,
  createJSONStorage(() => sessionStorage) as SyncStorage<string | undefined>, // sessionStorage
);

// 회원 PICK 수, 우승 수 tooltip 표현을 위한 카운트 정보
export const userTooltipAtom = atomWithStorage<{
  newFinalWinCount: number;
  newPickCount: number;
}>('userTooltip', {
  newFinalWinCount: 0,
  newPickCount: 0,
});

// MY랭킹 페이지 SNS 연결 팝업, 다시보지 않기 처리 시 30일동안 보지 않음
export const hideSnsPopupCheckedDateAtom = atomWithStorage<string | undefined>(
  'hideSnsPopupCheckedDate',
  undefined, // localStorage
);

// 홈 페이지 이벤트/프로모션 알림 허용 처리 팝업
export const isPushAgreePopupHiddenAtom = atomWithStorage<boolean>(
  'isPushAgreeHiddenPopup',
  false, // localStorage
);
